import React, { useEffect, useState } from "react";
import MarketItem from "../MarketItem/index";
import { ReactComponent as BinanceIcon } from "../../images/marketsIcons/binance.svg";
import { ReactComponent as BybitIcon } from "../../images/marketsIcons/bybit.svg";
import { getAllExchanges } from "../api/requests/requests";

interface Market {
    id: number;
    name: string;
    icon: React.ReactNode;
}

interface MarketsListProps {
    onMarketSelect: (marketName: string) => void;
}

const MarketsList: React.FC<MarketsListProps> = ({ onMarketSelect }) => {
    const [markets, setMarkets] = useState<Market[]>([]);

    const marketIcons: Record<string, React.ReactNode> = {
        Binance: <BinanceIcon />,
        Bybit: <BybitIcon />,
    };

    useEffect(() => {
        const fetchExchanges = async () => {
            const exchangeNames = await getAllExchanges();
            const fetchedMarkets = exchangeNames.map((name, index) => ({
                id: index + 1,
                name,
                icon: marketIcons[name] || null,
            }));
            setMarkets(fetchedMarkets);
        };

        fetchExchanges();
    }, []);

    return (
        <div className="flex gap-1 flex-col mt-2">
            {markets.length === 0 ? (
                <div className="text-white">Загрузка...</div>
            ) : (
                markets.map((market) => (
                    <MarketItem
                        key={market.id}
                        icon={market.icon}
                        name={market.name}
                        onSelect={() => onMarketSelect(market.name)}
                    />
                ))
            )}
        </div>
    );
};

export default MarketsList;
