import React from "react";

interface MarketItemProps {
  icon: React.ReactNode;
  name: string;
  onSelect: () => void;
}

const MarketItem: React.FC<MarketItemProps> = ({ icon, name, onSelect }) => {
  return (
    <div
      className="flex items-center justify-between py-2 text-white cursor-pointer"
      style={{
        borderBottom: "1px solid rgba(255, 255, 255, 0.08)",
      }}
      onClick={onSelect}
    >
      <div className="flex items-center gap-2">
        <div className="w-8 h-8">{icon}</div>
        <div
          style={{
            fontFamily: "Manrope",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "14.4px",
          }}
        >
          <span className="text-white">{name}</span>
        </div>
      </div>
      <div
        className="relative w-6 h-6 flex items-center justify-center transition"
        style={{
          color: "#2962FF",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{
            width: "16px",
            height: "16px",
          }}
        >
          <line x1="5" y1="12" x2="19" y2="12" />
          <polyline points="12 5 19 12 12 19" />
        </svg>
        <span
          className="absolute w-full h-full rounded-full"
          style={{
            border: "1.5px solid #2962FF",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        ></span>
      </div>
    </div>
  );
};

export default MarketItem;
