import React from "react";
import clsx from "clsx";

interface TitleProps {
    text: string;
    className?: string;
}

const Title: React.FC<TitleProps> = ({ text, className }) => {
    return (
        <h2
            className={clsx(
                "text-[32px] font-extrabold leading-[43.71px] text-white text-left w-full",
                className
            )}
            style={{
                fontFamily: "Manrope",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
            }}
        >
            {text}
        </h2>
    );
};

export default Title;
