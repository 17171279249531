import React, { FC } from "react";
import css from "./modal.module.css";

interface ModalProps {
  onClose?: () => void;
  onConfirm?: () => void;
  title?: string;
  content?: string;
}

const Modal: FC<ModalProps> = ({ onClose, onConfirm, title, content }) => {
  return (
    <>
      <div className={css.overlay} onClick={onClose} />
      <div className={css.wrapper}>
        {onClose && (
          <button className={css.close} onClick={onClose} aria-label="Close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="32"
              height="32"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="6" y1="6" x2="18" y2="18" />
              <line x1="6" y1="18" x2="18" y2="6" />
            </svg>
          </button>
        )}
        <div className={css.titleWrap}>
          {title && <span className={css.title}>{title}</span>}
        </div>
        {content && <p className={css.content}>{content}</p>}
        <div className={css.buttonsWrap}>

          {onConfirm && (
            <button className={css.confirmButton} onClick={onConfirm}>
              Удалить
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Modal;
