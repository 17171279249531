import React from "react";
import clsx from "clsx";

interface ButtonProps {
  text: string;
  label?: string;
  backgroundColor: string;
  className?: string;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  onClick?: () => void;
  disabled?: boolean; 
}

const Button: React.FC<ButtonProps> = ({
  text,
  label,
  backgroundColor = "#2962FF",
  className = "",
  onClick,
  Icon,
  disabled = false,
}) => {
  return (
    <button
      onClick={!disabled ? onClick : undefined} 
      className={clsx(
        `z-10 w-[342px] h-[64px] rounded-[8px] flex justify-center items-center text-white`,
        className,
        disabled && "cursor-not-allowed opacity-60"
      )}
      style={{
        backgroundColor: disabled ? "#B0BEC5" : backgroundColor,
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "16.2px",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
      }}
      disabled={disabled} 
    >
      {Icon && (
        <div className="mr-[8px]">
          <Icon />
        </div>
      )}
      <span>{text}</span>
      {label && (
        <span
          style={{
            fontFamily: "Manrope",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "12.6px",
            opacity: 0.48,
            marginTop: "4px",
          }}
        >
          {label}
        </span>
      )}
    </button>
  );
};

export default Button;
