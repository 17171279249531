import React from "react";
import clsx from "clsx";

interface DualTabsProps {
    firstText: string;
    secondText: string;
    activeTab: "first" | "second";
    setActiveTab: React.Dispatch<React.SetStateAction<"first" | "second">>;
}

const DualTabs: React.FC<DualTabsProps> = ({ firstText, secondText, activeTab, setActiveTab }) => {
    return (
        <div className="flex gap-0 justify-center items-center mt-6">
            <div
                className="flex flex-col items-center cursor-pointer"
                onClick={() => setActiveTab("first")}
            >
                <span
                    className={clsx(
                        "font-robotoCondensed text-[18px] font-medium leading-[16.2px] text-center uppercase text-white mb-1",
                        activeTab === "first" ? "text-opacity-100" : "text-opacity-40"
                    )}
                >
                    {firstText}
                </span>
                <div
                    className={clsx(
                        "w-[171px] h-[4px] mt-2 rounded-[5px]",
                        activeTab === "first" ? "bg-primary opacity-100" : "bg-white opacity-20"
                    )}
                ></div>
            </div>

            <div
                className="flex flex-col items-center cursor-pointer"
                onClick={() => setActiveTab("second")}
            >
                <span
                    className={clsx(
                        "font-robotoCondensed text-[18px] font-medium leading-[16.2px] text-center uppercase text-white mb-1",
                        activeTab === "second" ? "text-opacity-100" : "text-opacity-40"
                    )}
                >
                    {secondText}
                </span>
                <div
                    className={clsx(
                        "w-[171px] h-[4px] mt-2 rounded-[5px]",
                        activeTab === "second" ? "bg-primary opacity-100" : "bg-white opacity-20"
                    )}
                ></div>
            </div>
        </div>
    );
};

export default DualTabs;
