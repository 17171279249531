import clsx from "clsx";

interface ActionButtonProps {
    type: "Sell" | "Buy";
    style?: React.CSSProperties;
}

const ActionButton: React.FC<ActionButtonProps> = ({ type, style }) => {
    const isSell = type === "Sell";
    return (
        <div className="relative flex items-center justify-center" style={style}>
            <div
                className={clsx(
                    "absolute flex items-center justify-center px-2 text-white font-medium text-sm",
                    {
                        "bottom-4 bg-red-500": isSell,
                        "top-4 bg-green-500": !isSell,
                    },
                    "rounded-[8px] w-auto h-[28px] min-w-[48px] border-2 border-transparent"
                )}
            >
                {isSell ? "SELL" : "BUY"}
            </div>
            <div className="w-[10px] h-[10px] rounded-full bg-[#2962FF] border-[2px] border-white"></div>
        </div>
    );
};

export default ActionButton;
