import React from "react";
import { ReactComponent as ArrowIcon } from "../../images/icons/svg/arrow.svg";

interface BackButtonProps {
  onBack: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onBack }) => {
  return (
    <div
      onClick={onBack}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <ArrowIcon
        style={{
          marginRight: "8px",
          width: "24px",
          height: "24px",
        }}
      />
      <span
        style={{
          fontFamily: "Manrope, sans-serif",
          fontSize: "14px",
          lineHeight: "12.6px",
          color: "#FFFFFF",
          opacity: 0.4,
        }}
      >
        Вернуться
      </span>
    </div>
  );
};

export default BackButton;