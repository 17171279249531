import React from "react";
import CoinsItem from "../CoinsItem";
import { Coin } from "../../utils/Constants/constants";

interface CoinsListProps {
  coins?: Coin[];
  actionType: "add" | "remove";
  onAction: (coin: Coin) => void;
}

const CoinsList: React.FC<CoinsListProps> = ({
  coins,
  actionType,
  onAction,
}) => {
  return (
    <div className="flex flex-col mt-2 mb-4">
      {coins &&
        coins.map((coin) => (
          <CoinsItem
            key={coin.id}
            coin={coin}
            actionType={actionType}
            onAction={() => onAction(coin)}
          />
        ))}
      {!coins && (
        <div className="text-gray-400 text-center mt-4">
          Нет монет для отображения
        </div>
      )}
    </div>
  );
};

export default CoinsList;
