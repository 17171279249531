import { FC, useState } from "react";
import Footer from "../../components/Footer";
import BalanceScreen from "../BalanceScreen";
import CoinsScreen from "../CoinsScreen";
import ReferalsScreen from "../ReferalsSсreen";
export type TAutorizaedScreen = "balance" | "refProgram" | "signals";

const AutorizaedScreen: FC = () => {
  const [authorizedScreen, setAuthorizedScreen] =
    useState<TAutorizaedScreen>("signals");

  const AutorizaedScreenRender = () => {
    switch (authorizedScreen) {
      case "refProgram":
        return <ReferalsScreen />;
      case "balance":
        return <BalanceScreen />;
      case "signals":
        return <CoinsScreen />;
    }
  };
  return (
    <>
      {AutorizaedScreenRender()}
      <Footer
        setAuthorizedScreen={setAuthorizedScreen}
        activeItem={authorizedScreen}
      />
    </>
  );
};

export default AutorizaedScreen;
