import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../ui/Title";
import BackButton from "../../components/BackButton/index";
import TextInput from "../../ui/TextInput";
import CoinsList from "../../components/CoinsList";
import { RootState } from "../../store/store";
import {
  addBinanceCoin,
  addBybitCoin,
  setBinanceCoins,
  setBybitCoins,
} from "../../store/Slices/coinsSlice";
import Button from "../../ui/Button";
import { Coin } from "../../utils/Constants/constants";
import {
  getCoinsByExchange,
  getPrice,
  getUserActiveSubscriptions,
} from "../../components/api/requests/requests";
import { Subscription } from "../CoinsScreen";

interface SelectCoinScreenProps {
  onBack: () => void;
  market: string | null;
  onProceed: () => void;
}

const SelectCoinScreen: React.FC<SelectCoinScreenProps> = ({
  onBack,
  market,
  onProceed,
}) => {
  const [coinsSearch, setCoinsSearch] = useState("");
  const [coins, setCoins] = useState<Coin[]>([]);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const dispatch = useDispatch();

  const binanceCoins = useSelector(
    (state: RootState) => state.coins.binanceCoins
  );
  const bybitCoins = useSelector((state: RootState) => state.coins.bybitCoins);
  const selectedBinanceCoins = useSelector(
    (state: RootState) => state.coins.selectedBinanceCoins
  );
  const selectedBybitCoins = useSelector(
    (state: RootState) => state.coins.selectedBybitCoins
  );
  const totalPrice = useSelector((state: RootState) => state.coins.totalPrice);
  const selectedCoinsCount = useSelector(
    (state: RootState) =>
      state.coins.selectedBinanceCoins.length +
      state.coins.selectedBybitCoins.length
  );

  const combinedCoins = useMemo(() => {
    return [
      ...selectedBinanceCoins
        .map((selectedCoin) => {
          const coin = binanceCoins.find((c) => c.coin === selectedCoin.coin);
          if (!coin) return null;
          return { ...coin, exchange: "Binance" };
        })
        .filter((coin): coin is Coin => coin !== null),
      ...selectedBybitCoins
        .map((selectedCoin) => {
          const coin = bybitCoins.find((c) => c.coin === selectedCoin.coin);
          if (!coin) return null;
          return { ...coin, exchange: "Bybit" };
        })
        .filter((coin): coin is Coin => coin !== null),
    ];
  }, [binanceCoins, bybitCoins, selectedBinanceCoins, selectedBybitCoins]);

  useEffect(() => {
    if (market) {
      setLoading(true);
      getCoinsByExchange(market)
        .then((data) => {
          const formattedCoins: Coin[] = data.map((coinData, index) => {
            const coinName = coinData.name;
            const baseCoin = coinName.replace(/USDT$/, "");
            return {
              id: index + 1,
              coin: baseCoin,
              pair: "USDT",
              icon: coinData.icon,
              exchange: market || "Unknown",
            };
          });

          setCoins(formattedCoins);

          if (market === "Binance") {
            dispatch(setBinanceCoins(formattedCoins));
          } else if (market === "Bybit") {
            dispatch(setBybitCoins(formattedCoins));
          }
        })
        .catch((error) => {
          console.error("Ошибка при получении монет:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [market]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const data = await getUserActiveSubscriptions();
        setSubscriptions(data);
      } catch (err) {
        console.error("Error fetching subscriptions:", err);
      }
    };

    fetchSubscriptions();
  }, []);

  const filteredCoins = useMemo(() => {
    return coins.filter((coin) => {
      const isInSubscription = subscriptions.some(
        (sub) =>
          sub.coin_name === coin.coin + coin.pair &&
          sub.exchange_name === coin.exchange
      );
      const isInCombined = combinedCoins.some(
        (selectedCoin) =>
          selectedCoin.coin === coin.coin &&
          selectedCoin.exchange === coin.exchange
      );
      return (
        !isInSubscription &&
        !isInCombined &&
        coin.coin.toLowerCase().includes(coinsSearch.toLowerCase())
      );
    });
  }, [coins, subscriptions, combinedCoins, coinsSearch]);

  const handleAddCoin = async (coin: Coin) => {
    try {
      const price = await getPrice();
      if (market === "Binance") {
        dispatch(addBinanceCoin({ coin: coin.coin, price }));
      } else if (market === "Bybit") {
        dispatch(addBybitCoin({ coin: coin.coin, price }));
      }
    } catch (error) {
      console.error("Ошибка при добавлении монеты:", error);
    }
  };

  return (
    <div className="mt-14 flex flex-col w-full px-6 mb-24 min-h-[80vh]">
      <BackButton onBack={onBack} />
      <Title text={`Выберите монеты`} className="mb-6" />
      <TextInput
        placehoder="Поиск монет"
        classes={{
          root: "mb-[12px] bg-white/5",
          input: "text-[16px] font-semibold max-h-[64px] border-0 bg-white/5",
        }}
        value={coinsSearch}
        onChange={setCoinsSearch}
        icon={
          coinsSearch && (
            <button
              onClick={() => setCoinsSearch("")}
              className="absolute right-5 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
            >
              ✕
            </button>
          )
        }
      />
      {loading ? (
        <div className="text-white">Загрузка монет...</div>
      ) : (
        <CoinsList
          coins={filteredCoins}
          actionType="add"
          onAction={handleAddCoin}
        />
      )}
      {selectedCoinsCount > 0 && (
        <div className="fixed bottom-[100px]">
          <Button
            text={`${selectedCoinsCount} монет в корзине`}
            backgroundColor="#2962FF"
            className="mt-4 flex flex-col gap-[4px] "
            onClick={onProceed}
          />
          <p className="text-white backdrop-blur-[12px] text-center py-1">
            1 пара стоит $5 <br /> и оплачивается по курсу в TON
          </p>
        </div>
      )}
    </div>
  );
};

export default SelectCoinScreen;
