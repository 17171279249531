import { FC } from "react";
import { ReactComponent as Refers } from "../../images/footer/icon-referals.svg";
import { ReactComponent as Signals } from "../../images/footer/icon-signal.svg";
import { ReactComponent as Wallet } from "../../images/icons/svg/icon-wallet.svg";
import { TAutorizaedScreen } from "../../Screens/AutorizaedScreen";
interface IFooter {
  setAuthorizedScreen: React.Dispatch<React.SetStateAction<TAutorizaedScreen>>;
  activeItem: TAutorizaedScreen;
}
interface IMenuItem {
  icon: any;
  label: string;
  id: TAutorizaedScreen;
}
const menuItems: IMenuItem[] = [
  { icon: <Wallet width={32} height={32} />, label: "Баланс", id: "balance" },
  { icon: <Signals width={32} height={32} />, label: "Сигналы", id: "signals" },
  {
    icon: <Refers width={32} height={32} />,
    label: "Партнерка",
    id: "refProgram",
  },
];

const Footer: FC<IFooter> = ({ setAuthorizedScreen, activeItem }) => {
  const handleClick = (id: TAutorizaedScreen) => {
    setAuthorizedScreen(id);
  };
  return (
    <div className="w-full h-[74px] bg-[#060D1F] fixed bottom-0 left-0 flex justify-between items-center z-50 shadow-footer px-[24px]">
      {menuItems.map((item) => (
        <div
          style={{ transition: "2s" }}
          key={item.id}
          className={`w-[78px] flex flex-col items-center cursor-pointer gap-1 ${
            activeItem === item.id ? "text-[#2863FF]" : "text-[#6A6E79]"
          }`}
          onClick={() => handleClick(item.id)}
        >
          {item.icon}
          <p className="text-[12px]">{item.label}</p>
        </div>
      ))}
    </div>
  );
};

export default Footer;
