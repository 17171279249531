import React, { useState } from "react";
import Modal from "../../ui/RemoveModal/Modal";
import { Coin } from "../../utils/Constants/constants";
import { ReactComponent as CrossIcon } from "../../images/icons/svg/Cross.svg";
import coinImage from "../../images/coinsIcons/1.svg";
import MiddleModal from "../midleModal";
import TextInput from "../../ui/TextInput";
import Button from "../../ui/Button";
import { updateSubscription } from "../api/requests/requests";

interface CoinsItemProps {
  coin: Coin;
  actionType: "add" | "remove";
  onAction: () => void;
  modalAction?: () => void;
}

const CoinsItem: React.FC<CoinsItemProps> = ({
  coin,
  actionType,
  onAction,
  modalAction,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalProcentActive, setIsModalProcentActive] = useState(false);
  const [coinProcent, setCoinProcent] = useState<string | undefined>(
    coin.procent
  );

  const handleChange = (newValue: string) => {
    if (isNaN(Number(newValue))) return;
    if (newValue.length >= 8) return;
    setCoinProcent(newValue);
  };

  const handleActionClick = () => {
    if (actionType === "remove") {
      setModalOpen(true);
    } else {
      onAction();
    }
  };

  const confiModalAction = () => {
    if (modalAction) modalAction();
    setIsModalProcentActive(false);
  };

  const confirmAction = () => {
    onAction();
    setModalOpen(false);
  };

  const handleSubmitProcent = async () => {
    if (!coinProcent) {
      console.error("Процент монеты не указан");
      return;
    }

    const requestBody = {
      percent_threshold: Number(coinProcent), 
      coin_name: coin.coin + coin.pair, 
      exchange_name: coin.exchange,
    };

    try {
      const response = await updateSubscription(
        { percent_threshold: requestBody.percent_threshold },
        requestBody.coin_name,
        requestBody.exchange_name
      );
      console.log("Запрос успешен:", response);
    } catch (error) {
      console.error("Ошибка при отправке запроса:", error);
    }

    confiModalAction(); 
  };

  const icon =
    coin.icon && coin.icon.length > 0
      ? `https://signals-back.taskcomplete.ru/${coin.icon}`
      : coinImage;

  return (
    <>
      <div
        className="flex items-center justify-between py-2 text-white"
        style={{
          borderBottom: "1px solid rgba(255, 255, 255, 0.08)",
        }}
      >
        <div className="flex items-center gap-2">
          <div className="w-8 h-8">
            <img src={icon} alt={coin.fullName} />
          </div>
          <div className="flex items-center justify-center gap-4">
            <span
              className="flex items-center gap-4"
              style={{
                fontFamily: "Manrope",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "14.4px",
              }}
            >
              <div>
                <span className="text-white">{coin.coin}</span>
                <span
                  style={{
                    opacity: 0.4,
                  }}
                >
                  {coin.pair}
                </span>
              </div>
            </span>

            <span
              style={{
                opacity: 0.4,
              }}
              className="text-[12px]"
            >
              {coin.date}
            </span>
            <span>{coin.fullName}</span>
          </div>
        </div>

        <div className="flex gap-4">
          {coin.procent && (
            <div
              className="bg-[#FFFFFF29] py-[2px] px-3 rounded-[4px] text-[12px] h-[22px]  text-center"
              onClick={() => setIsModalProcentActive(true)}
            >
              {coin.procent}%
            </div>
          )}

          <button
            className="relative w-6 h-6 flex items-center justify-center transition"
            onClick={handleActionClick}
            style={{
              color:
                actionType === "remove"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "#2962FF",
            }}
          >
            {actionType === "remove" ? (
              <CrossIcon />
            ) : (
              <>
                +
                <span
                  className="absolute w-full h-full rounded-full"
                  style={{
                    border: "1px solid #2962FF",
                    top: 1,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                ></span>
              </>
            )}
          </button>
        </div>
      </div>
      {isModalProcentActive && (
        <MiddleModal
          onClose={() => {
            setIsModalProcentActive(false);
          }}
          isActive={isModalProcentActive}
        >
          <p className="text-[24px] font-bold leading-[32.78px] font-sans mb-[12px]">
            Сигнал продажи
          </p>
          <p className="font-medium text-[13px] mb-6">
            Введите на сколько % должна вырасти монета, чтобы получать сигналы
            на продажу
          </p>
          <p className="text-[14px] font-bold leading-[19.12px] font-sans mb-[9px]">
            Введите %
          </p>
          <TextInput
            placehoder="Введите сумму"
            classes={{
              root: "mb-[12px]",
              input:
                "bg-gradient-to-r from-[#060D1F] to-[#141D36] text-[14px] max-h-[64px] text-white border-0",
            }}
            value={coinProcent}
            onChange={handleChange}
          />

          <Button
            onClick={handleSubmitProcent} 
            backgroundColor="#2962FF"
            text="Отправить заявку"
            className="max-w-[310px]"
          />
        </MiddleModal>
      )}

      {isModalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          title={`Вы уверены, что хотите удалить ${coin.coin} (${coin.pair})?`}
          onConfirm={confirmAction}
        />
      )}
    </>
  );
};

export default CoinsItem;
