import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Coin } from "../../utils/Constants/constants";

interface SelectedCoin {
  exchange: string;
  coin: string;
}

interface CoinsState {
  binanceCoins: Coin[];
  bybitCoins: Coin[];
  selectedBinanceCoins: SelectedCoin[];
  selectedBybitCoins: SelectedCoin[];
  totalPrice: number;
}

const initialState: CoinsState = {
  binanceCoins: [],
  bybitCoins: [],
  selectedBinanceCoins: [],
  selectedBybitCoins: [],
  totalPrice: 0,
};

const coinsSlice = createSlice({
  name: "coins",
  initialState,
  reducers: {
    clearBasket(state) {
      state.selectedBinanceCoins = [];
      state.selectedBybitCoins = [];
      state.totalPrice = 0;
    },
    setBinanceCoins(state, action: PayloadAction<Coin[]>) {
      state.binanceCoins = action.payload;
    },
    setBybitCoins(state, action: PayloadAction<Coin[]>) {
      state.bybitCoins = action.payload;
    },
    addBinanceCoin(
      state,
      action: PayloadAction<{ coin: string; price: number }>
    ) {
      const newCoin = { exchange: "Binance", coin: action.payload.coin };
      if (!state.selectedBinanceCoins.find((c) => c.coin === newCoin.coin)) {
        state.selectedBinanceCoins.push(newCoin);
        state.totalPrice += action.payload.price;
      }
    },
    addBybitCoin(
      state,
      action: PayloadAction<{ coin: string; price: number }>
    ) {
      const newCoin = { exchange: "Bybit", coin: action.payload.coin };
      if (!state.selectedBybitCoins.find((c) => c.coin === newCoin.coin)) {
        state.selectedBybitCoins.push(newCoin);
        state.totalPrice += action.payload.price;
      }
    },
    removeBinanceCoin(
      state,
      action: PayloadAction<{ coin: string; price: number }>
    ) {
      state.selectedBinanceCoins = state.selectedBinanceCoins.filter(
        (c) => c.coin !== action.payload.coin
      );
      state.totalPrice -= action.payload.price;
    },
    removeBybitCoin(
      state,
      action: PayloadAction<{ coin: string; price: number }>
    ) {
      state.selectedBybitCoins = state.selectedBybitCoins.filter(
        (c) => c.coin !== action.payload.coin
      );
      state.totalPrice -= action.payload.price;
    },
  },
});

export const {
  clearBasket,
  setBinanceCoins,
  setBybitCoins,
  addBinanceCoin,
  addBybitCoin,
  removeBinanceCoin,
  removeBybitCoin,
} = coinsSlice.actions;

export default coinsSlice.reducer;
