import React, { useEffect, useState } from "react";
import Slider from "./components/Slider";
import Button from "./ui/Button";
import AutorizaedScreen from "./Screens/AutorizaedScreen";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import { api } from "./components/api/api";
import { setCookie } from "./helpers/cockieHelper";

type TAppPage = "stories" | "authorized";

// const { initDataRaw } = retrieveLaunchParams();

// const login = async () => {
//   if (!initDataRaw) return;
//   try {
//     const body = {
//       init_data: initDataRaw,
//     };

//     const response = await api.post("auth/telegram-auth/", body);
//     setCookie("auth", `Bearer ${response.data.access_token}`, 1000);
//     return response.data;
//   } catch (error) {
//     return false;
//   }
// };

const login = async () => {
  try {
    const response = await api.get("auth/token/");
    setCookie("auth", `Bearer ${response.data.access_token}`, 1000);
    console.log("Rdata", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const App: React.FC = () => {
  const [appPage, setAppPage] = useState<TAppPage>("stories");

  useEffect(() => {
    login();
  }, []);
  const AppNavigation = () => {
    switch (appPage) {
      case "stories":
        return (
          <>
            <Slider />

            <Button
              backgroundColor="#2962FF"
              text="Начать работу"
              className="fixed bottom-[8vh]"
              onClick={() => {
                setAppPage("authorized");
              }}
            />
          </>
        );
      case "authorized":
        return <AutorizaedScreen />;
    }
  };
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        {AppNavigation()}
      </div>
      <div id="portal-root"></div>
    </>
  );
};

export default App;
