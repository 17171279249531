import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperInstance } from "swiper";
import clsx from "clsx";
import "swiper/css";
import slide1 from "../../images/slider/slide1.png";
import slide2 from "../../images/slider/slide2.png";
import slide3 from "../../images/slider/slide3.png";
import line from "../../images/slider/lineSlide2.svg";
import ActionButton from "../../ui/ActionButton";
import Title from "../../ui/Title";

interface SlideProps {
  title: string;
  text: string;
  image: string;
}

const slides: SlideProps[] = [
  {
    title: "Сигналы в Телеграм по любой бирже",
    text: "Получайте точные торговые сигналы в реальном времени для любых криптобирж. Подходит как для новичков, так и для профессионалов!",
    image: slide1,
  },
  {
    title: "Получайте сигналы быстрее биржи",
    text: "Получайте сигналы быстрее, чем на бирже благодаря ИИ Volctrade, предсказывающему рыночные тренды с опережением!",
    image: slide2,
  },
  {
    title: "Торгуйте с элитными инсайдами",
    text: "Аналитика, прогнозы и рекомендации от ИИ Volctrade помогут вам принимать быстрые и уверенные решения.",
    image: slide3,
  },
];

const Slider: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperInstance | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      swiperRef.current?.slideNext();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleNextSlide = () => {
    swiperRef.current?.slideNext();
  };

  const handlePrevSlide = () => {
    swiperRef.current?.slidePrev();
  };

  return (
    <div className="top-[110px] short:top-[105px] max-w-full mx-auto relative">
      <div className="absolute top-[-65px] left-0 right-0 flex justify-between items-center h-2 px-4 mt-4">
        {slides.map((_, index) => (
          <div
            key={index}
            className={clsx(
              "flex-1 h-[5px] mx-1 rounded-[7px]",
              activeIndex === index ? "bg-white" : "bg-white opacity-25"
            )}
          ></div>
        ))}
      </div>

      <div
        className="absolute top-0 left-0 w-1/2 h-full z-10 cursor-pointer"
        onClick={handlePrevSlide}
      ></div>
      <div
        className="absolute top-0 right-0 w-1/2 h-full z-10 cursor-pointer"
        onClick={handleNextSlide}
      ></div>

      <Swiper
        className="mySwiper"
        loop={true} 
        onSlideChange={(swiper: SwiperInstance) => {
          const realIndex = swiper.realIndex;
          setActiveIndex(realIndex);
        }}
        onSwiper={(swiper: SwiperInstance) => (swiperRef.current = swiper)}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="flex flex-col items-start text-left relative">
              <Title text={slide.title} className="px-6" />
              <p
                className={clsx(
                  "text-[14px] font-medium leading-[22.4px] mt-4 text-white opacity-80 px-6"
                )}
                style={{
                  fontFamily: "Manrope",
                  textUnderlinePosition: "from-font",
                  textDecorationSkipInk: "none",
                }}
              >
                {slide.text}
              </p>
              <div className="relative h-[560px] short:h-[500px]">
                <img
                  src={slide.image}
                  alt={slide.title}
                  className={clsx("max-w-none h-auto object-cover mb-4 mt-4", {
                    "relative top-[-60px]": index === 0,
                    "relative top-[80px]": index === 1,
                    "relative top-[-20px]": index === 2,
                  })}
                />
                {index === 1 && (
                  <div className="absolute inset-0">
                    <img
                      src={line}
                      alt="line"
                      className="absolute top-[77px]"
                    />
                    <ActionButton
                      type="Sell"
                      style={{ top: "97px", left: "-167px" }}
                    />
                    <ActionButton
                      type="Buy"
                      style={{ top: "185px", left: "-110px" }}
                    />
                    <ActionButton
                      type="Sell"
                      style={{ top: "122px", left: "-50px" }}
                    />
                    <ActionButton
                      type="Sell"
                      style={{ top: "182px", left: "102px" }}
                    />
                    <ActionButton
                      type="Buy"
                      style={{ top: "247px", left: "160px" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
