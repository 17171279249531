import { FC, useEffect, useState } from "react";
import { ReactComponent as XIcon } from "../../images/icons/svg/x-icon.svg";
import { ReactComponent as UserIcon } from "../../images/icons/svg/icon-user.svg";
import { ReactComponent as WalletIcon } from "../../images/icons/svg/icon-wallet.svg";
import { ReactComponent as QuestionIcon } from "../../images/icons/svg/icon-question.svg";
import { ReactComponent as CopyIcon } from "../../images/icons/svg/icon-copy.svg";
import Table from "../../ui/Table";
import { getCookie } from "../../helpers/cockieHelper";
import { api } from "../../components/api/api";
import { shortenString } from "../../helpers/dataHelper";
import { IHisoryElement } from "../BalanceScreen";
import Title from "../../ui/Title";

interface BalanceResponse {
  balance: number;
}

const copyTextToClipboard = async (text: string) => {
  try {
    alert("Ссылка скопирована");
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error("Ошибка:", err);
    alert("Попробуйте еще раз");
  }
};

const ReferalsScreen: FC = () => {
  const [contextModal, setContext] = useState<boolean>(false);
  const [balance, setBalance] = useState<number | undefined>();
  const [referalLink, setReferalLink] = useState<string>("");
  const [referalCount, setReferalCount] = useState<number | undefined>();
  const [history, setHistory] = useState<IHisoryElement[]>([]);
  const token = getCookie("auth");

  const getHistory = async () => {
    try {
      const response = await api.get<IHisoryElement[]>(
        "auth/me/history?type=REFERRAL_FEES",
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setHistory(response.data);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getReferalLink = async () => {
    try {
      const response = await api.get<{ referral_link: string }>(
        "adviser/user/referral-link/",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setReferalLink(response.data.referral_link);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getReferalCount = async () => {
    try {
      const response = await api.get<{ referrals_count: number }>(
        "adviser/user/referrals-count/",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setReferalCount(response.data.referrals_count);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getBalance = async () => {
    try {
      const response = await api.get<BalanceResponse>("adviser/user/balance/", {
        headers: {
          Authorization: token,
        },
      });
      setBalance(response.data.balance);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    getHistory();
    getReferalCount();
    getBalance();
    getReferalLink();
  }, []);
  return (
    <div className="mt-[80px] w-full grid justify-items-center font-bold text-white">
      <div className="px-[24px]">
        <div className="flex justify-between w-full mb-6 items-center">
          {/* <p className="text-[32px] leading-[43.71px]">Партнерская программа</p> */}
          <Title text="Партнерская программа" />
          <div
            className="bg-white/10 rounded-full p-[0] w-[52px] h-[44px] flex justify-center items-center"
            onClick={() => setContext((prev) => !prev)}
          >
            {!contextModal && <QuestionIcon width={32} height={32} />}
            {contextModal && <XIcon width={32} height={32} />}
          </div>
        </div>

        {contextModal && (
          <div className="px-[16px] py-[24px] bg-[#202D50] rounded-[16px] absolute w-[342px] z-[25] top-[152px] text-[14px] leading-[18.2px]">
            <p>
              Basic{" "}
              <span className="font-medium">
                - каждому пользователю, который приобрел как минимум 1 сигнал,
                присваивается статус Basic. На данном статусе открыта 1
                реферальная линия с доходом 20% с покупки каждого лично
                приглашенного реферала.
              </span>
            </p>
            <br />
            <p>
              Vip{" "}
              <span className="font-medium">
                - когда в первой линии пользователя появляются 10 партнеров со
                статусом Basic, то пользователю присваивается статус Vip, и
                открывается:
              </span>
            </p>
            <br />
            <ul className="list-disc ml-[20px] font-medium">
              <li>
                реферальная линия партнерской программы - 20% с покупок каждого
                партнера второй реферальной линии;
              </li>
              <li>
                реферальная линия партнерской программы - 10% с покупок каждого
                партнера третьей реферальной линии.
              </li>
            </ul>
            <br />
            <p className="font-medium">
              Для подтверждения статуса реферальной программы необходима
              ежемесячная квалификация - личная покупка минимум{" "}
              <p>1 сигнала в месяц.</p>
            </p>
          </div>
        )}

        <div className="flex justify-center items-center w-full flex-col mb-[40px]">
          <p className="text-14px opacity-[40%] mb-[8px] font-semibold">
            Ваша ссылка{" "}
          </p>
          <div className="flex justify-center w-[277px] justify-center gap-[16px] items-center mb-[17px]">
            <p className="text-[24px] font-medium uppercase">
              {shortenString(referalLink)}
            </p>
            <div>
              <CopyIcon onClick={() => copyTextToClipboard(referalLink)} />
            </div>
          </div>
          <div className="flex justify-center items-center opacity-[50%] gap-[24px]">
            <div className="flex justify-center items-center gap-1 font-semibold">
              <div className="w-[24px]">
                <UserIcon />
              </div>
              <p>{referalCount}</p>
            </div>
            <div className="flex justify-center items-center gap-1 font-semibold">
              <div className="w-[24px]">
                <WalletIcon width="24" height="24" />
              </div>

              <p> {balance} USDT</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-px bg-white opacity-24 opacity-[40%] mb-[40px]"></div>
      <div className="px-[24px] w-full">
        <Table history={history} />
      </div>
    </div>
  );
};

export default ReferalsScreen;
