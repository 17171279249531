import { FC } from "react";
import TableField from "../TableField";
import { IHisoryElement } from "../../Screens/BalanceScreen";

interface ITable {
  history: IHisoryElement[];
}
const Table: FC<ITable> = ({ history }) => {
  const historyRender = () => {
    if (history.length > 0) {
      return history.map((el) => {
        return (
          <div key={el.id}>
            <TableField
              userName={el.detail.referral_id.toString()}
              amount={el.change}
            />
          </div>
        );
      });
    } else {
      return (
        <p className="text-white text-[18px] mt-[13px] text-center ">
          Нет рефералов
        </p>
      );
    }
  };
  return (
    <div className="w-full">
      <div className="mb-[8px] w-full flex justify-between px-4">
        <span className="text-white opacity-40 text-[14px]">Пользователь</span>
        <span className="text-white opacity-40 text-[14px]">Сумма</span>
      </div>
      <div className="flex flex-col gap-1">{historyRender()}</div>
    </div>
  );
};

export default Table;
