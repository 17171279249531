import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  removeBinanceCoin,
  removeBybitCoin,
} from "../../store/Slices/coinsSlice";
import {
  deactivateSubscription,
  getUserActiveSubscriptions,
} from "../../components/api/requests/requests";
import Title from "../../ui/Title";
import DualTabs from "../../ui/DualTabs";
import CoinsList from "../../components/CoinsList";
import Button from "../../ui/Button";
import SelectExchangeScreen from "../SelectExchangeScreen";
import { Coin } from "../../utils/Constants/constants";
import MiddleModal from "../../components/midleModal";
import TextInput from "../../ui/TextInput";

export interface Subscription {
  expires_at: any;
  coin_name: string;
  exchange_name: string;
  subscription_id: number;
  icon_url: string;
}

const MockCoins = [
  {
    id: 1,
    coin: "aboa",
    pair: "aba123",
    date: "11.09.2001",
    icon: "",
    exchange: "1223",
    procent: "20",
  },
];

const CoinsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<"binance" | "bybit">("binance");
  const [screen, setScreen] = useState<"coins" | "selectExchange">("coins");
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [error, setError] = useState<string | null>(null);

  const fetchSubscriptions = async () => {
    console.log("fetchSubscriptions");
    try {
      const data = await getUserActiveSubscriptions();
      console.log(data);
      setSubscriptions(data);
    } catch (err) {
      setError("Ошибка загрузки подписок");
      console.error("Error fetching subscriptions:", err);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const handleRemoveCoin = async (coin: Coin) => {
    const coinName = `${coin.coin}USDT`;
    const exchangeName = activeTab === "binance" ? "Binance" : "Bybit";

    console.log("Отправляем параметры:", {
      coin_name: coinName,
      exchange_name: exchangeName,
    });

    try {
      const response = await deactivateSubscription(coinName, exchangeName);
      if (response.message) {
        console.log(response.message);
        dispatch(
          activeTab === "binance"
            ? removeBinanceCoin({ coin: coin.coin, price: 0 })
            : removeBybitCoin({ coin: coin.coin, price: 0 })
        );

        fetchSubscriptions();
      } else if (response.error) {
        console.error(response.error);
      }
    } catch (err) {
      console.error("Ошибка при удалении подписки:", err);
    }
  };

  const filteredSubscriptions = subscriptions.filter(
    (sub) =>
      (activeTab === "binance" && sub.exchange_name === "Binance") ||
      (activeTab === "bybit" && sub.exchange_name === "Bybit")
  );

  const coinsToDisplay = filteredSubscriptions.map((sub) => {
    const [base] = sub.coin_name.split("USDT");
    const dateOnly = sub.expires_at.includes(" ")
      ? sub.expires_at.split(" ")[0]
      : sub.expires_at;

    return {
      id: sub.subscription_id,
      coin: base,
      pair: "USDT",
      date: dateOnly,
      icon: `${sub.icon_url}`,
      exchange: sub.exchange_name,
      procent: "20",
    };
  });

  if (error) return <p>{error}</p>;

  if (screen === "selectExchange") {
    return (
      <SelectExchangeScreen
        onBack={() => setScreen("coins")}
        market={activeTab === "binance" ? "Binance" : "Bybit"}
        onAddCoin={() => console.log("Добавление монеты")}
      />
    );
  }

  return (
    <div className="mt-20 mb-[65px] relative min-h-[80vh]">
      <Title text="Ваши подписки" className="px-0" />
      <DualTabs
        firstText="Binance"
        secondText="Bybit"
        activeTab={activeTab === "binance" ? "first" : "second"}
        setActiveTab={(tab) =>
          setActiveTab(tab === "first" ? "binance" : "bybit")
        }
      />
      <CoinsList
        coins={coinsToDisplay}
        actionType="remove" 
        onAction={handleRemoveCoin}
      />
      <Button
        text="Добавить монету"
        backgroundColor="#2962FF"
        className="mt-3 fixed bottom-[100px]"
        onClick={() => setScreen("selectExchange")}
      />
    </div>
  );
};

export default CoinsScreen;
