import { FC } from "react";

interface ITableField {
  userName: string;
  amount: string;
}

const TableField: FC<ITableField> = ({ userName, amount }) => {
  return (
    <div
      className="text-white text-[18px] flex justify-between p-[19px_16px_23px_16px] bg-gradient-to-r from-[#060D1F] to-[#141D36] rounded-lg"
      style={{ fontWeight: "600" }}
    >
      <span>{userName}</span> <span>{amount} USDT</span>
    </div>
  );
};

export default TableField;
