import { FC } from "react";
import { ReactComponent as SuccessIcon } from "../../images/icons/svg/icon-success.svg";
import { ReactComponent as FalledIcon } from "../../images/icons/svg/icon-falled.svg";

import css from "./modal.module.css";
import LoaderIcon from "./loader-icon.component";
import Button from "../Button";
import { TStatus } from "../../Screens/PaymentScreen";
import { useDispatch } from "react-redux";
import { clearBasket } from "../../store/Slices/coinsSlice";

interface ModalProps {
  onClose?: () => void;
  type?: TStatus;
  onClick?: () => void;
  onBack?: () => void;
}

const Modal: FC<ModalProps> = ({
  onClose,
  type = "pending",
  onBack,
  onClick,
}) => {
  const dispatch = useDispatch();
  const RenderModal = () => {
    switch (type) {
      case "pending":
        return (
          <>
            {" "}
            <div className={css.iconWrap}>
              <div className={css.loaderWrap}>
                <LoaderIcon />
              </div>
            </div>
            <div className={css.titleWrap}>
              <span className={css.title}>Ожидаем оплату</span>
            </div>
          </>
        );
      case "paid":
        return (
          <>
            <div className={css.iconWrap}>
              <SuccessIcon />
            </div>
            <div>
              <p className={css.title}>Успешная оплата</p>
            </div>
            {onBack && (
              <Button
                onClick={() => {
                  dispatch(clearBasket());
                  onBack();
                }}
                text={"Ок"}
                backgroundColor="#2962FF"
                className="max-w-[100%] mx-auto mb-[95px]"
              />
            )}
          </>
        );
      case "falled":
        return (
          <>
            {" "}
            <div className={css.iconWrap}>
              <FalledIcon />
            </div>
            <div>
              <span className={css.title}>Платеж не прошел</span>
            </div>
            {onClick && (
              <Button
                onClick={onClick}
                text={"Повторить платеж"}
                backgroundColor="#2962FF"
                className="max-w-[100%] mx-auto mb-[95px] mt-[20px]"
              />
            )}
          </>
        );
    }
  };
  return (
    <>
      <div className={css.overlay} />
      <div className={css.wrapper}>
        <div className={css.hole}></div>
        {onClose && (
          <button className={css.close} onClick={onClose} aria-label="Close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="32"
              height="32"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="6" y1="6" x2="18" y2="18" />
              <line x1="6" y1="18" x2="18" y2="6" />
            </svg>
          </button>
        )}
        {RenderModal()}
      </div>
    </>
  );
};

export default Modal;
